import { IconCheckedBox, IconCheckedBoxSecondary } from 'components/atoms/icon'
import { url } from 'inspector'
import styles from 'styles/components/molecules/agreement.module.scss'
import urls from 'utils/helpers/url'

export enum AgreementVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

type AgreeMentProps = {
  activeBox: boolean
  onClickBox: () => void
  IsStrokeOutline?: boolean
  variant?: AgreementVariant
}

export const Agreement = ({
  activeBox,
  onClickBox,
  IsStrokeOutline = false,
  variant = AgreementVariant.Primary,
}: AgreeMentProps) => {
  const buttonVersion = () => {
    switch (variant) {
      case AgreementVariant.Secondary:
        return styles.secondary
      case AgreementVariant.Primary:
        return styles.primary
      default:
        return styles.primary
    }
  }

  const agreementTerms = styles.agreementTerms
  return (
    <div className={` ${agreementTerms} ${buttonVersion()}`}>
      <div onMouseDown={onClickBox} style={{ marginTop: 2 }}>
        {variant === AgreementVariant.Primary ? (
          <IconCheckedBox
            IsStrokeOutline={IsStrokeOutline}
            isActive={activeBox}
            width={16}
            height={16}
          />
        ) : (
          <IconCheckedBoxSecondary
            IsStrokeOutline={IsStrokeOutline}
            isActive={activeBox}
            width={16}
            height={16}
          />
        )}
      </div>
      <p className={styles.textOption}>
        Saya menyetujui{' '}
        <a
          className={styles.textRedirect}
          target="_blank"
          href={urls.externalUrls.termsAndConditions}
          rel="noreferrer"
        >
          Syarat & Ketentuan
        </a>{' '}
        serta{' '}
        <a
          className={styles.textRedirect}
          target="_blank"
          href={urls.externalUrls.privacyPolicy}
          rel="noreferrer"
        >
          Kebijakan Privasi
        </a>{' '}
        yang berlaku, dan bersedia menerima informasi promo dari SEVA.
      </p>
    </div>
  )
}
