import { AxiosResponse } from 'axios'
import { defaultCSANumber } from 'utils/helpers/const'
import urls from 'utils/helpers/url'
import { getLocalStorage } from 'utils/handler/localStorage'
import { UTMTagsData } from 'utils/types/utils'
import { ContactType, LeadsUsedCar, LocalStorageKey } from 'utils/enum'
import {
  CountryCodePlusSign,
  defaultContactFormValue,
} from 'utils/hooks/useContactFormData/useContactFormData'
import {
  decryptValue,
  encryptValue,
  encryptedPrefix,
} from 'utils/handler/encryption'
import {
  postCustomerAssistantDetails,
  postUnverifiedLeadsDealer,
  postUnverifiedLeadsNew,
  postUnverifiedLeadsNewUsedCar,
} from 'services/api'
import {
  MoengageEventName,
  setTrackEventMoEngage,
} from 'services/moengage/moengage'
import ReportSentry from '../sentry'

export enum UnverifiedLeadSubCategory {
  SEVA_NEW_CAR_LP_LEADS_FORM = 'SEVNCLFH',
  SEVA_NEW_CAR_HUBUNGI_KAMI = 'SEVNCCUS',
  SEVA_NEW_CAR_CAR_OF_THE_MONTH = 'SEVNCCOM',
  SEVA_NEW_CAR_PROMO_LEADS_FORM = 'SEVNCPLF',
  SEVA_NEW_CAR_PDP_LEADS_FORM = 'SEVNCVLF',
  SEVA_NEW_CAR_PLP_LEADS_FORM = 'SEVNCSPF',
  SEVA_NEW_CAR_SEARCH_WIDGET = 'SEVNCSWG',
  SEVA_NEW_CAR_OFFLINE_EVENT_FORM_COLD = 'SEVNCOEC',
  SEVA_NEW_CAR_OFFLINE_EVENT_FORM_HOT = 'SEVNCOEH',
  OTO_NEW_CAR_PDP_LEADS_FORM = 'OTONCPDP',
  OTO_NEW_CAR_PLP_LEADS_FORM = 'OTONCPLP',
  OTO_NEW_CAR_LP_LEADS_FORM = 'OTONCLPF',
  SEVA_PTBC = 'SEVNCLPP',
  SEVA_NEW_CAR_RETENTION_ENGINE = 'SEVNCRE',
}

interface GetCustomerAssistantResponse {
  crmId: string
  name: string
  phoneNumber: string
}

interface CreateUnverifiedLeadRequestNew {
  origination: UnverifiedLeadSubCategory
  name?: string
  phoneNumber: string
  contactType?: ContactType
  email?: string
  sevaKnowledge?: string
  isPurchaseSoon?: boolean
  sevaCarBrands?: string[]
  otherCarBrand?: string[]
  paymentPreference?: string
  income?: string
  age?: string
  tenure?: number
  dp?: number
  otrPrice?: number
  monthlyInstallment?: number
  promo?: string
  carBrand?: string
  carModelText?: string
  carVariantText?: string
  cityId?: number
  platform?: string
  companyCode?: string | number
  npk?: number | string
  eventName?: string
  isTncAccepted?: boolean
}

interface CreateUnverifiedLeadRequestDealer {
  origination: UnverifiedLeadSubCategory
  name?: string
  phoneNumber: string
  cityId?: number
  carBrand?: string
  dealerName?: string
  isTncAccepted?: string
}

export interface CreateUnverifiedLeadRequestNewUsedCar {
  origination: LeadsUsedCar
  customerName?: string
  phoneNumber: string
  selectedTenure?: number
  selectedTdp?: string
  selectedInstallment?: string
  priceFormatedNumber?: string
  carId?: string
  makeName?: string
  modelName?: string
  variantName?: string
  skuCode?: string
  colourName?: string
  engineCapacity?: string
  priceValue?: string
  seat?: string
  isTncAccepted?: boolean
  variantTitle?: string
  transmission?: string
  fuelType?: string
  productCat?: string
  nik?: number
  cityName?: string
  plate?: string
  mileage?: number
  taxDate?: string
  partnerName?: string
  partnerId?: number
  sevaUrl?: string
  cityId?: string
  tenureAR?: number
  tenureTLO?: number
}

export const createUnverifiedLeadNew = (
  requestBody: CreateUnverifiedLeadRequestNew,
) => {
  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
  return postUnverifiedLeadsNew({
    ...requestBody,
    utmSource: UTMTags?.utm_source,
    utmMedium: UTMTags?.utm_medium,
    utmCampaign: UTMTags?.utm_campaign,
    utmId: UTMTags?.utm_id,
    utmContent: null, // temporary
    utmTerm: UTMTags?.utm_term,
    adSet: UTMTags?.adset,
  })
}

export const createUnverifiedLeadDealer = (
  requestBody: CreateUnverifiedLeadRequestDealer,
) => {
  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
  return postUnverifiedLeadsDealer({
    ...requestBody,
    utmCampaign: UTMTags?.utm_campaign ?? null,
    utmMedium: UTMTags?.utm_medium ?? null,
    utmSource: UTMTags?.utm_source ?? null,
    utmContent: UTMTags?.utm_content ?? null, // temporary
    adSet: UTMTags?.adset ?? null,
    utmTerm: UTMTags?.utm_term ?? null,
  })
}

export const createUnverifiedLeadNewUsedCarPLP = (
  requestBody: CreateUnverifiedLeadRequestNewUsedCar,
) => {
  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
  return postUnverifiedLeadsNewUsedCar({
    ...requestBody,
    utmSource: UTMTags?.utm_source ?? null,
    utmMedium: UTMTags?.utm_medium ?? null,
    utmCampaign: UTMTags?.utm_campaign ?? null,
    utmContent: UTMTags?.utm_content ?? null, // temporary
    utmTerm: UTMTags?.utm_term ?? null,
    utmAdset: UTMTags?.adset ?? null,
  })
}

export const createUnverifiedLeadNewUsedCar = (
  requestBody: CreateUnverifiedLeadRequestNewUsedCar,
) => {
  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
  return postUnverifiedLeadsNewUsedCar({
    ...requestBody,
    utmSource: UTMTags?.utm_source ?? null,
    utmMedium: UTMTags?.utm_medium ?? null,
    utmCampaign: UTMTags?.utm_campaign ?? null,
    utmContent: null, // temporary
    utmTerm: UTMTags?.utm_term ?? null,
    utmAdset: UTMTags?.adset ?? null,
  })
}

export const getCustomerAssistantWhatsAppNumber = async () => {
  let csaWhatsAppNumber = defaultCSANumber
  return `${urls.externalUrls.whatsappUrlPrefix}${csaWhatsAppNumber}`
}

export const trackMoengageSubmitLeads = (name: string, phone: string) => {
  const obj = {
    first_name: name,
    mobile_number: phone,
  }
  setTrackEventMoEngage(MoengageEventName.submit_leads_form, obj)
}
