const MonthlyInstallment = 'Cicilan per bulan'
const TotalDP = 'Total DP'
const PhoneNumber = 'Nomor Handphone'
const Seater = 'Seater'
const LimitPriceRange = {
  Min: 'Harga harus lebih kecil dari Harga Tertinggi',
  Max: 'Harga harus lebih besar dari Harga Terendah',
}
const OtpTooManyRequest =
  'Kamu sudah meminta OTP. Mohon tunggu beberapa saat sebelum meminta OTP baru.'
const CommonError =
  'Mohon maaf, terjadi kendala jaringan. Silakan coba kembali.'
const OtpLandingPageTooManyRequest =
  'Terlalu banyak percobaan OTP. Silakan ulangi beberapa saat lagi.'
const LeadsFormSuccessToastMessage =
  'Berhasil dikirim. Agen SEVA akan segera menghubungi kamu dalam 1x24 jam.'

export const Wording = {
  MonthlyInstallment,
  TotalDP,
  LimitPriceRange,
  OtpTooManyRequest,
  CommonError,
  OtpLandingPageTooManyRequest,
  PhoneNumber,
  Seater,
  LeadsFormSuccessToastMessage,
}
