import React, { useContext, useEffect, useState } from 'react'
import SupergraphicRight from '/public/revamp/illustration/supergraphic-secondary-small.webp'
import styles from 'styles/components/organisms/leadsFormTertiary.module.scss'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { UnverifiedLeadSubCategory } from 'utils/types/models'
import {
  filterNonDigitCharacters,
  onlyLettersAndSpaces,
} from 'utils/handler/stringManipulation'
import { AmplitudeEventName } from 'services/amplitude/types'
import { sendAmplitudeData } from 'services/amplitude'
import { decryptValue, encryptValue } from 'utils/handler/encryption'
import { getLocalStorage, saveLocalStorage } from 'utils/handler/localStorage'
import elementId from 'utils/helpers/trackerId'
import { Button, Gap, Input, InputPhone } from 'components/atoms'
import Image from 'next/image'
import { IconLoading } from 'components/atoms/icon'
import { setTrackEventMoEngageWithoutValue } from 'services/moengage'
import { FunnelQueryContext, FunnelQueryContextType } from 'services/context'
import { useRouter } from 'next/router'
import { OTP } from 'components/organisms/otp'
import { LocalStorageKey } from 'utils/enum'
import { CityOtrOption } from 'utils/types'
import { ButtonSize, ButtonVersion } from 'components/atoms/button'
import {
  getTemanSevaStatus,
  trackEventCountly,
  valueForUserTypeProperty,
} from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { getToken } from 'utils/handler/auth'
import { getCustomerInfoSeva } from 'utils/handler/customer'
import { postUnverifiedLeadsNew } from 'services/api'
import { capitalizeWords } from 'utils/handler/string'
import { createUnverifiedLeadDealer } from 'utils/handler/lead'
import { replaceIndex0 } from 'utils/handler/string'
import { trackMoengageSubmitLeads } from 'utils/handler/lead'
import ReportSentry from 'utils/handler/sentry'
import dynamic from 'next/dynamic'
import { OtpV2ReceiverType } from 'components/organisms/otpV2'
import { Wording } from 'utils/helpers/glosary/glosary'
import { useUtils } from 'services/context/utilsContext'
import { sendOtpCodeByWhatsappAndSms } from 'utils/handler/otp'
import { trackEventGTM } from 'services/gtm'
import { GTMNames } from 'services/gtm/gtmNames'
import clsx from 'clsx'
import { Agreement } from 'components/molecules/agreement'

const OTPV2 = dynamic(
  () => import('components/organisms').then((mod) => mod.OTPV2),
  { ssr: false },
)

const Toast = dynamic(
  () => import('components/atoms').then((mod) => mod.Toast),
  { ssr: false },
)

interface PropsLeadsForm {
  otpStatus?: any
  onVerify?: (e: any) => void
  onFailed?: (e: any) => void
  onPage?: string
  cityId?: number
  cityName?: string
  isDealer?: boolean
  branchName?: string
  trackGTMLeads?: string
  GTMDealer?: string
  pageOrigination?: string
  pageOriginationOnClickField?: string
  additionalContainerClassname?: string
  headingText?: string
  subheadingText?: string
  isUsingBorderRadius?: boolean
  additionalForegroundClassname?: string
  overwriteLeadsPayload?: any
}

const LeadsFormTertiary: React.FC<PropsLeadsForm> = ({
  onPage,
  isDealer,
  cityId,
  cityName,
  branchName,
  trackGTMLeads,
  GTMDealer,
  pageOrigination = 'Homepage - Bottom Section',
  pageOriginationOnClickField = 'Homepage - Floating Icon',
  additionalContainerClassname,
  headingText = isDealer ? 'Dapatkan Informasi Promo' : 'Butuh Bantuan?',
  subheadingText = isDealer
    ? 'Untuk tahu lebih lanjut terkait promo yang tersedia, yuk ngobrol dengan agen SEVA. Kami akan menghubungi kamu dalam 1x24jam.'
    : 'Untuk tahu lebih lanjut, yuk ngobrol dengan Agen SEVA. Kami akan menghubungi kamu dalam 1x24 jam.',
  isUsingBorderRadius,
  additionalForegroundClassname,
  overwriteLeadsPayload = undefined,
}: any) => {
  const router = useRouter()
  const getUrlBrand = router.query.brand?.toString() ?? ''
  const { funnelQuery } = useContext(
    FunnelQueryContext,
  ) as FunnelQueryContextType
  const platform = 'web'
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [isTncAccepted, setIsTncAccepted] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFilled, setIsFilled] = useState<boolean>(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
  const [cityOtr] = useLocalStorage<CityOtrOption | null>(
    LocalStorageKey.CityOtr,
    null,
  )
  const referralCodeFromUrl: string | null = getLocalStorage(
    LocalStorageKey.referralTemanSeva,
  )
  const [modalOpened, setModalOpened] = useState<
    'otp' | 'success-toast' | 'none'
  >('none')
  const [otpV2Receiver, setOtpV2Receiver] =
    useState<OtpV2ReceiverType>('whatsapp')
  const [isOpenToastOtpV2, setIsOpenToastOtpV2] = useState(false)
  const [toastMessageOtpV2, setToastMessageOtpV2] = useState(
    Wording.CommonError,
  )
  const { setLastOtpSentTime } = useUtils()
  const [, setLastOptSentPhoneNumber] = useLocalStorage<string>(
    LocalStorageKey.LastOtpSentPhoneNumber,
    '',
  )
  const [shake, setShake] = useState(false)

  useEffect(() => {
    getDataCustomer()
  }, [])

  const handleInputName = (payload: any): void => {
    if (payload !== ' ' && onlyLettersAndSpaces(payload)) {
      setName(payload)
      checkInputValue(payload, phone)
    }
  }

  const handleInputPhone = (payload: any): void => {
    const temp = filterNonDigitCharacters(payload)
    if (temp[0] === '8' || temp === '') {
      setPhone(temp)
      checkInputValue(name, temp)
    }
  }

  const checkInputValue = (name: string, phone: string): void => {
    if (name !== '' && phone?.length > 8 && isTncAccepted) setIsFilled(true)
    else setIsFilled(false)
  }

  const verified = (): void => {
    const data = {
      name,
      phone,
    }
    setModalOpened('none')
    saveFlagLeads(data)
    sendUnverifiedLeads()
  }

  const trackCountlyOtpView = () => {
    trackEventCountly(CountlyEventNames.WEB_OTP_VIEW, {
      PAGE_ORIGINATION: pageOrigination,
    })
    onClickSendLeads('No')
  }

  const onSuccessRequestOtpV2Handler = (
    receiver: OtpV2ReceiverType,
    responseApi: any,
  ) => {
    setIsLoading(false)
    setLastOtpSentTime(Date.now())
    setLastOptSentPhoneNumber(encryptValue(phone.toString()))
    setOtpV2Receiver(receiver)
    trackCountlyOtpView()
    setModalOpened('otp')
  }

  const onFailedTooManyRequestV2Handler = (errorObject: any) => {
    setIsLoading(false)
    setToastMessageOtpV2(Wording.OtpTooManyRequest)
    setIsOpenToastOtpV2(true)
    setTimeout(() => {
      setIsOpenToastOtpV2(false)
    }, 3000)
  }

  const onGeneralFailedV2Handler = (errorObject: any) => {
    setIsLoading(false)
    setToastMessageOtpV2(Wording.CommonError)
    setIsOpenToastOtpV2(true)
    setTimeout(() => {
      setIsOpenToastOtpV2(false)
    }, 3000)
  }

  const requestOtpV2Handler = () => {
    sendOtpCodeByWhatsappAndSms(
      phone,
      onSuccessRequestOtpV2Handler,
      onFailedTooManyRequestV2Handler,
      onGeneralFailedV2Handler,
    )
  }

  const sendOtpCode = async () => {
    setIsLoading(true)
    const dataLeads = checkDataFlagLeads()
    if (dataLeads) {
      if (phone === dataLeads.phone && name === dataLeads.name) {
        sendUnverifiedLeads()
        onClickSendLeads('Yes')
      } else if (phone === dataLeads.phone && name !== dataLeads.name) {
        sendUnverifiedLeads()
        updateFlagLeadsName(name)
        onClickSendLeads('Yes')
      } else {
        requestOtpV2Handler()
      }
    } else if (isUserLoggedIn) {
      sendUnverifiedLeads()
      onClickSendLeads('Yes')
    } else {
      requestOtpV2Handler()
    }
  }

  const saveFlagLeads = (payload: { name: string; phone: string }): void => {
    const now = new Date()
    const expiry = now.getTime() + 7 * 24 * 60 * 60 * 1000
    const data = { ...payload, expiry }
    const encryptedData = encryptValue(JSON.stringify(data))
    saveLocalStorage(LocalStorageKey.flagLeads, encryptedData)
  }

  const updateFlagLeadsName = (payload: string): void => {
    const data: any = getLocalStorage(LocalStorageKey.flagLeads)
    const decryptedValue: any = JSON.parse(decryptValue(data))
    const newData = { ...decryptedValue, name: payload }
    const encryptedData = encryptValue(JSON.stringify(newData))
    saveLocalStorage(LocalStorageKey.flagLeads, encryptedData)
  }

  const sendUnverifiedLeads = async (): Promise<void> => {
    let data
    const mainPayload = {
      platform,
      name,
      phoneNumber: phone,
      isTncAccepted: true,
    }
    const temanSevaStatus = await getTemanSevaStatus(referralCodeFromUrl)

    if (onPage === 'main' && isDealer) {
      data = {
        platform,
        name,
        phoneNumber: phone,
        isTncAccepted: isTncAccepted,
        origination: UnverifiedLeadSubCategory.DEALER_LP_LEADS_FORM,
      }
    } else if (onPage === 'brand' && isDealer) {
      data = {
        platform,
        name,
        phoneNumber: phone,
        isTncAccepted: isTncAccepted,
        carBrand:
          getUrlBrand === 'bmw'
            ? getUrlBrand.toUpperCase()
            : capitalizeWords(getUrlBrand),
        promo:
          getUrlBrand === 'bmw'
            ? getUrlBrand.toUpperCase()
            : capitalizeWords(getUrlBrand),
        origination: UnverifiedLeadSubCategory.DEALER_BRAND_LEADS_FORM,
      }
    } else if (onPage === 'location' && isDealer) {
      data = {
        platform,
        name,
        phoneNumber: phone,
        isTncAccepted: isTncAccepted,
        carBrand:
          getUrlBrand === 'bmw'
            ? getUrlBrand.toUpperCase()
            : capitalizeWords(getUrlBrand),
        cityId: cityId,
        promo:
          getUrlBrand === 'bmw'
            ? getUrlBrand.toUpperCase()
            : capitalizeWords(getUrlBrand) + ' ' + cityName,
        origination: UnverifiedLeadSubCategory.DEALER_BRAND_LOCATION_LEADS_FORM,
      }
    } else if (onPage === 'detail' && isDealer) {
      data = {
        platform,
        name,
        phoneNumber: phone,
        isTncAccepted: isTncAccepted,
        carBrand:
          getUrlBrand === 'bmw'
            ? getUrlBrand.toUpperCase()
            : capitalizeWords(getUrlBrand),
        cityId: cityId,
        branchName: branchName,
        origination: UnverifiedLeadSubCategory.DEALER_DETAIL_LEADS_FORM,
      }
    } else {
      data = {
        origination: UnverifiedLeadSubCategory.SEVA_NEW_CAR_LP_LEADS_FORM,
        ...(cityOtr?.id && { cityId: cityOtr.id }),
        ...(funnelQuery.downPaymentAmount && {
          dp: parseInt(funnelQuery.downPaymentAmount as string),
        }),
        ...(funnelQuery.monthlyInstallment && {
          monthlyInstallment: parseInt(
            funnelQuery.monthlyInstallment as string,
          ),
        }),
      }
    }

    const payload =
      overwriteLeadsPayload !== undefined
        ? {
            ...mainPayload,
            ...data,
            ...overwriteLeadsPayload,
          }
        : { ...mainPayload, ...data }

    try {
      setIsLoading(true)
      isDealer
        ? await createUnverifiedLeadDealer(payload)
        : await postUnverifiedLeadsNew(payload)
      if (trackGTMLeads === 'homepage') {
        trackEventGTM(GTMNames.WEB_HOMEPAGE_LEADS_FORM_SUCCESS)
      }
      if (isDealer) {
        trackEventGTM(GTMDealer)
      }
      trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_SUCCESS_VIEW, {
        PAGE_ORIGINATION: pageOrigination,
        LOGIN_STATUS: isUserLoggedIn ? 'Yes' : 'No',
        TEMAN_SEVA_STATUS: temanSevaStatus,
        PHONE_NUMBER: '+62' + phone,
      })
      setModalOpened('success-toast')
      trackMoengageSubmitLeads(name, phone)
      setIsLoading(false)
      onSubmitLeadSuccess()
      setTimeout(() => setModalOpened('none'), 5000)
    } catch (error: any) {
      setIsLoading(false)
      ReportSentry(`LeadsForm Tertiary - sendUnverifiedLeads`, error)
    }
  }

  const onSubmitLeadSuccess = (): void => {
    sendAmplitudeData(AmplitudeEventName.SELECT_HOME_SEND_DETAILS, {})
    sendAmplitudeData(AmplitudeEventName.WEB_LANDING_PAGE_LEADS_FORM_SUBMIT, {
      WA_Chat: false,
    })
    setTrackEventMoEngageWithoutValue('leads_created')

    if (typeof window !== undefined) {
      window.dataLayer.push({
        event: 'interaction',
        eventCategory: 'Leads Generator',
        eventAction: 'Homepage - Leads Form - Control',
        eventLabel: 'Kirim Rincian',
      })
    }
  }

  const getDataCustomer = async (): Promise<void> => {
    const data: string | null = getLocalStorage(LocalStorageKey.sevaCust)
    if (data !== null) {
      const user = JSON.parse(decryptValue(data))
      setName(user.fullName)
      setPhone(user.phoneNumber.replace('+62', ''))
      setIsUserLoggedIn(true)
      setIsFilled(true)
    } else {
      const dataLeads = checkDataFlagLeads()
      if (dataLeads) {
        setName(dataLeads.name)
        setPhone(dataLeads.phone)
        setIsFilled(true)
      }
    }
  }

  const checkDataFlagLeads = () => {
    const now = new Date()
    const flagLeads: any | null = getLocalStorage(LocalStorageKey.flagLeads)
    if (flagLeads !== null) {
      const parsedLeads = JSON.parse(decryptValue(flagLeads))
      if (now.getTime() > parsedLeads.expiry) {
        localStorage.removeItem(LocalStorageKey.flagLeads)
        return
      } else {
        return parsedLeads
      }
    }
  }

  const onClickNameField = () => {
    trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_NAME_CLICK, {
      PAGE_ORIGINATION: pageOriginationOnClickField,
      USER_TYPE: valueForUserTypeProperty(),
    })
  }

  const onClickPhoneField = () => {
    trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_PHONE_NUMBER_CLICK, {
      PAGE_ORIGINATION: pageOriginationOnClickField,
      USER_TYPE: valueForUserTypeProperty(),
    })
  }

  const onClickSendLeads = async (PHONE_VERIFICATION_STATUS: string) => {
    trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_SEND_CLICK, {
      PAGE_ORIGINATION: 'Homepage - Bottom Section',
      LOGIN_STATUS: isUserLoggedIn ? 'Yes' : 'No',
      PHONE_VERIFICATION_STATUS: PHONE_VERIFICATION_STATUS,
      PHONE_NUMBER: '+62' + phone,
    })
  }

  useEffect(() => {
    const ctaButton = document.getElementById('cta-button')
    const ctaButtonOverview = document.getElementById('cta-button-overview')

    const action = () => {
      const timeout = setTimeout(() => {
        setShake(true)
        clearTimeout(timeout)
      }, 1000)
    }

    ctaButton?.addEventListener('click', action)
    ctaButtonOverview?.addEventListener('click', action)

    return () => {
      ctaButton?.removeEventListener('click', action)
      ctaButtonOverview?.removeEventListener('click', action)
    }
  })

  useEffect(() => {
    if (shake) {
      const timeout = setTimeout(() => {
        setShake(false)
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [shake])

  const handleTnc = (e?: React.ChangeEvent<HTMLInputElement>) => {
    setIsTncAccepted(!isTncAccepted)
  }

  useEffect(() => {
    checkInputValue(name, phone)
  }, [isTncAccepted])

  return (
    <div
      id="leads-form-tertiary"
      className={clsx({
        [styles.container]: true,
        [additionalContainerClassname]: additionalContainerClassname,
        ['shake-animation-X']: shake,
      })}
    >
      <div className={styles.wrapper}>
        <div
          className={`${styles.background} ${
            isUsingBorderRadius && styles.borderRadius8px
          }`}
        >
          <div
            className={`${styles.wrapperSupergraphicRight} ${
              isUsingBorderRadius && styles.borderRadius8px
            }`}
          >
            <Image
              src={SupergraphicRight}
              alt="seva-vector-red-rounded"
              width={200}
              height={140}
              className={styles.supergraphicRight}
            />
          </div>
        </div>

        <div
          className={`${styles.foreground} ${additionalForegroundClassname}`}
        >
          <h1 className={styles.textHeading}>{headingText}</h1>
          <p className={styles.textSubHeading}>{subheadingText}</p>
          <div className={styles.form}>
            <Input
              className={styles.inputName}
              dataTestId={
                router.pathname === ''
                  ? elementId.Field.FullName
                  : elementId.PDP.LeadsForm.name
              }
              placeholder="Masukkan nama lengkap"
              title="Nama Lengkap"
              value={name}
              onChange={(e: any) => handleInputName(e.target.value)}
              data-testid={elementId.Field.FullName}
              onFocus={onClickNameField}
            />
            <InputPhone
              className={styles.inputPhone}
              dataTestId={
                router.pathname === ''
                  ? elementId.Field.PhoneNumber
                  : elementId.PDP.LeadsForm.phone
              }
              disabled={isUserLoggedIn}
              placeholder="Contoh: 812345678"
              title="Nomor Handphone"
              value={phone}
              onChange={(e: any) => handleInputPhone(e.target.value)}
              data-testid={elementId.Field.FullName}
              onFocus={onClickPhoneField}
            />
            <Agreement
              activeBox={isTncAccepted}
              onClickBox={() => handleTnc()}
            />
            <Button
              secondaryClassName={styles.button}
              data-test-id={
                router.pathname === ''
                  ? elementId.Homepage.Button.CariMobil
                  : elementId.PDP.LeadsForm.btnSend
              }
              disabled={!isFilled}
              version={
                isFilled ? ButtonVersion.PrimaryDarkBlue : ButtonVersion.Disable
              }
              size={ButtonSize.Big}
              onClick={() => sendOtpCode()}
            >
              {isLoading ? (
                <div className={`${styles.iconWrapper} rotateAnimation`}>
                  <IconLoading width={14} height={14} color="#FFFFFF" />
                </div>
              ) : (
                'Kirim'
              )}
            </Button>
          </div>
        </div>
      </div>
      {modalOpened === 'otp' && (
        <OTPV2
          isOpened
          phoneNumber={phone}
          closeModal={() => {
            setModalOpened('none')
          }}
          isOtpVerified={() => verified()}
          receiver={otpV2Receiver}
        />
      )}
      <Toast
        width={343}
        text={Wording.LeadsFormSuccessToastMessage}
        open={modalOpened === 'success-toast'}
      />
      <Toast
        typeToast="error2"
        text={toastMessageOtpV2}
        width={343}
        open={isOpenToastOtpV2 && !!toastMessageOtpV2}
        additionalTextClassname={styles.toastAdditionalTextClassname}
      />
    </div>
  )
}

export default LeadsFormTertiary
